import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

class About extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="About" keywords={[`gatsby`, `About`, `react`]} />
        <div className="site-About">
          <div className="container">
            <div className="row">
              <h2>About Us</h2>
              <p>
                We make healthy lunches for local people. James, our founder, is
                a serial entrepreneur who's decided to break away from the rat
                race and set up a falafel shop!
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default About
